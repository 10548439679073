
import { mapGetters } from 'vuex'
import { mdiPencil, mdiContentSave, mdiClose } from '@mdi/js'
import UsageBar from '@/components/user/UsageBar'
export default {
  name: 'UserComponent',
  components: { UsageBar },
  model: {
    prop: 'user',
    event: 'update',
  },
  props: {
    limit: {
      type: Number,
      default: null,
    },
    usage: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  data: () => ({
    mdiContentSave,
    mdiPencil,
    mdiClose,
    isEditingLimit: false,
    newLimit: 0,
  }),
  computed: {
    ...mapGetters(['maxTokens']),
  },
  watch: {
    newLimit(newVal) {
      this.$nextTick(() => {
        if (newVal > this.maxTokens) {
          this.newLimit = this.maxTokens
        }
        if (newVal < 0) {
          this.newLimit = 0
        }
      })
    },
  },
  methods: {
    onEditLimit() {
      this.isEditingLimit = true
      this.newLimit = this.limit || this.maxTokens
    },
    onSaveLimit() {
      setTimeout(() => {
        this.$emit('change', this.newLimit)
      }, 350)
      this.isEditingLimit = false
    },
    onLimitChange(newLimit) {
      this.$emit('change', newLimit)
    },
  },
}
