
import { mapGetters } from 'vuex'

export default {
  name: 'UsageBar',
  model: {
    prop: 'limit',
    event: 'change',
  },
  props: {
    limit: {
      type: Number,
      default: null,
    },
    usage: {
      type: Number,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  computed: {
    ...mapGetters(['maxTokens']),
    progressBuffer() {
      return (this.limit / this.maxTokens) * 100
    },
    progress() {
      return (this.usage / this.maxTokens) * 100
    },
    progressListener() {
      if (!this.editable) return {}
      return {
        change: this.onLimitDrag,
      }
    },
  },
  methods: {
    onLimitDrag(newVal) {
      const newLimit = this.toHundred((newVal / 100) * this.maxTokens)
      if (newLimit >= this.usage) {
        this.$emit('change', newLimit)
      }
      this.$refs.progress.internalLazyValue = this.progress
    },
    toHundred(val) {
      val = parseInt(val)
      return Math.round(val / 100) * 100
    },
  },
}
